import React, { ReactElement } from 'react'

import { Image } from '~/components/core/image'
import { Link } from '~/components/core/link'
import { IAdvicesBlockImageProps } from '~/components/core/advicesBlock'
import { Icon } from '~/components/core/icon'

import styles from './AdvicesBlockImage.module.scss'

const AdvicesBlockImage = (props: IAdvicesBlockImageProps): ReactElement => {
	const { link, image, title, isVideo } = props

	return (
		<Link
			href={ link }
			theme={ {
				wrapper: styles.wrapper,
				link: styles.image,
			} }
			ariaLabel={ title }
			tabIndex={ -1 }
		>
			{ /* TODO Check and possibly change after thumbnails fixes from API */ }

			<Image
				fill
				className={ styles.image }
				alt={ title }
				src={ image }
			/>

			{ isVideo && (
				<div className={ styles.iconWrapper }>
					<div className={ styles.icon }>
						<Icon
							name="play"
							width={ 128 }
							height={ 128 }
						/>
					</div>
				</div>
			) }
		</Link>
	)
}

export { AdvicesBlockImage }
