import { noop } from 'lodash'

import { IPageable } from '~/api/dataTypes/pageable'
import { ICmsContextProps } from '~/providers/cmsProvider'

export const INITIAL_CMS_PAGEABLE: IPageable = {
	totalElements: 0,
	totalPages: 0,
	hasNext: false,
	firstPage: false,
	lastPage: false,
	hasPrevious: false,
	pageSize: 0,
	pageNumber: 0,
}

export const INITIAL_CMS_PROVIDER: ICmsContextProps = {
	content: [],
	path: '',
	pageable: INITIAL_CMS_PAGEABLE,
	isPending: false,
	handleSetData: noop,
	handleSetPending: noop,
}
