import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './GiftCardDetailsHeader.module.scss'

const GiftCardDetailsHeader = (): ReactElement => {
	const { t } = useTranslation(['giftCard'])

	return (
		<header className={ styles.wrapper }>
			<div className={ styles.dateTime }>
				{ t('details.dateTime') }
			</div>

			<div className={ styles.type }>
				{ t('details.type') }
			</div>

			<div className={ styles.status }>
				{ t('details.status') }
			</div>

			<div className={ styles.amount }>
				{ t('details.amount') }
			</div>

			<div className={ styles.accountBalance }>
				{ t('details.accountBalance') }
			</div>
		</header>
	)
}

export { GiftCardDetailsHeader }
