import { AxiosResponse } from 'axios'

import { IInspirationPageResponse, IInspirationCategoriesPageData, IInspirationsCardPageResponse, IInspirationCategoriesPageResponse, IInspirationCategoriesResponse, IGetInspirationsCategoriesPageParams } from '~/api/dataTypes/inspiration'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getInspirationCategoryMain = async (noCache?: string | string[]): Promise<AxiosResponse<IInspirationPageResponse & IInspirationCategoriesPageData>> => (
	await request.get('/v1/inspirations/categories/category-main', {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)

export const getInspirationCategory = async (id: number, noCache?: string | string[]): Promise<AxiosResponse<IInspirationPageResponse>> => (
	await request.get(`/v1/inspirations/categories/${ id }`, {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)

export const getInspirationCard = async (id: number, noCache?: string | string[]): Promise<AxiosResponse<IInspirationsCardPageResponse | null>> => (
	await request.get(`/v1/inspirations/${ id }`, {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)

export const getInspirationsCategoriesPage = async (params: IGetInspirationsCategoriesPageParams): Promise<AxiosResponse<IInspirationCategoriesPageResponse>> => {
	const {
		categoryId,
		page = 1,
		isInternal = true,
		noCache = '',
	} = params

	return await request.get(`/v1/inspirations/categories/${ categoryId }/page`, {
		isInternal,
		service: 'CMS_INTEGRATION_SERVICE',
		params: {
			page,
			...getNoCacheParam(noCache),
		},
	})
}

export const getInspirationCategories = async (noCache?: string | string[]): Promise<AxiosResponse<IInspirationCategoriesResponse>> => (
	await request.get('/v1/inspirations/categories', {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)
