import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { IAdviceCardResponse, IAdviceNodeResponse, IAdvicesByNodeParams, IAdviceByNodeResponse, IAdviceHashtagListResponse, IHashtagAdvicesListResponse, IHashtagResponse, IAdvicesByHashtagParams } from '~/api/dataTypes/advice'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getAdvice = async (id: number, noCache?: string | string[]): Promise<AxiosResponse<IAdviceCardResponse>> => (
	await request.get(`/v1/articles/${ id }`, {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal: true,
		params: getNoCacheParam(noCache),
	})
)

export const getAdviceMenu = async (noCache?: string | string[], nodeId?: number): Promise<AxiosResponse<IAdviceNodeResponse>> => (
	await request.get('/v1/articles/menu', {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal: true,
		params: {
			nodeId,
			noCache,
		},
	})
)

export const getAdvicesByNode = async (params: IAdvicesByNodeParams): Promise<AxiosResponse<IAdviceByNodeResponse>> => {
	const {
		id,
		articleTypeIdList = [],
		sort = 'default',
		page = 1,
		size = 12,
		isInternal = false,
		noCache = '',
	} = params

	return await request.get(`/v1/articles/${ id }/articles`, {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal,
		params: {
			articleTypeIdList,
			sort,
			page,
			size,
			noCache,
		},
		paramsSerializer: (params: IAdvicesByNodeParams): string => queryString.stringify(params, { skipNull: true, skipEmptyString: true }),
	})
}

export const getAdviceHashtagList = async (noCache?: string | string[]): Promise<AxiosResponse<IAdviceHashtagListResponse>> => (
	await request.get('/v1/articles-hashtags', {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal: true,
		params: getNoCacheParam(noCache),
	})
)

export const getHashtag = async (hashtagId: number, noCache?: string | string[]): Promise<AxiosResponse<IHashtagResponse>> => (
	await request.get(`/v1/articles-hashtags/${ hashtagId }`, {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal: true,
		params: getNoCacheParam(noCache),
	})
)

export const getAdvicesByHashtag = async (params: IAdvicesByHashtagParams): Promise<AxiosResponse<IHashtagAdvicesListResponse>> => {
	const {
		hashtagId,
		articleTypeIdList = [],
		sort = 'default',
		page = 1,
		size = 12,
		isInternal = false,
		noCache = '',
	} = params

	return await request.get(`/v1/articles-hashtags/${ hashtagId }/articles`, {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal,
		params: {
			articleTypeIdList,
			sort,
			page,
			size,
			noCache,
		},
		paramsSerializer: (params: IAdvicesByNodeParams): string => queryString.stringify(params, { skipNull: true, skipEmptyString: true }),
	})
}
