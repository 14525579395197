import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { map } from 'lodash'

import { IAdvicesBlockContentProps } from '~/components/core/advicesBlock'
import { ArrowLink } from '~/components/core/arrowLink'
import { Link } from '~/components/core/link'
import { IAdviceHashtag } from '~/api/dataTypes/advice'

import styles from './AdvicesBlockContent.module.scss'

const AdvicesBlockContent = (props: IAdvicesBlockContentProps): ReactElement => {
	const { title, text, link, hashtags } = props
	const { t } = useTranslation(['common'])

	const renderHashtags = useCallback((): ReactElement[] => (
		map(hashtags, ({ name, wwwUrl }: IAdviceHashtag): ReactElement => (
			<li key={ `hashtag-advice-card-${ name }` } className={ styles.tag }>
				<Link href={ wwwUrl }>
					{ name }
				</Link>
			</li>
		))
	), [hashtags])

	return (
		<div className={ styles.wrapper }>
			<div>
				<ul className={ styles.tagList }>
					{ renderHashtags() }
				</ul>

				<h3>
					<Link
						href={ link }
						theme={ { wrapper: styles.title } }
						ariaLabel={ `${ title } - ${ text }` }
					>
						{ title }
					</Link>
				</h3>

				<div className={ styles.text }>
					{ text }
				</div>
			</div>

			<Link
				href={ link }
				ariaLabel={ t('readMore') }
			>
				<ArrowLink text={ t('readMore') } />
			</Link>
		</div>
	)
}

export { AdvicesBlockContent }
