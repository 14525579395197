import React, { ReactElement } from 'react'

import { SectionHeader } from '~/components/core/sectionHeader'
import { IAdvicesBlockHeaderProps } from '~/components/core/advicesBlock'

import styles from './AdvicesBlockHeader.module.scss'

const AdvicesBlockHeader = (props: IAdvicesBlockHeaderProps): ReactElement => {
	const { title, description } = props

	return (
		<>
			<SectionHeader title={ title } />

			<div className={ styles.text }>
				{ description }
			</div>
		</>
	)
}

export { AdvicesBlockHeader }
