import React, { ReactElement, useEffect, useRef, useCallback } from 'react'
import { useWindowScroll } from 'react-use'
import { isUndefined } from 'lodash'

import { GiftCardBlockWrapper, GiftCardBowAdornment, GiftCardFormWithDetails } from '~/components/giftCard'
import { useCmsBlocks } from '~/hooks/cmsBlocks'
import { IGiftCardComponentProps } from '~/components/giftCard/giftCardComponent/types'
import { CmsBlock } from '~/components/core/cmsBlock'

import styles from './GiftCardComponent.module.scss'

const GiftCardComponent = (props: IGiftCardComponentProps): ReactElement => {
	const { fullPageData, isLazyLoading } = props
	const { content, handleLoadMoreBlocks, pageNumber, totalPages } = useCmsBlocks()
	const { y } = useWindowScroll()
	const ref = useRef<HTMLDivElement | null>(null)

	const renderBlock = useCallback((index: number): ReactElement | null => {
		const cmsData = isLazyLoading ? content[index]?.block : fullPageData?.slots[index]?.blockList[0]

		if (isUndefined(cmsData)) {
			return null
		}

		return (
			<CmsBlock
				key={ cmsData.name }
				content={ cmsData.content }
				type={ cmsData.type }
				name={ cmsData.name }
			/>
		)
	}, [isLazyLoading, content, fullPageData])

	useEffect(() => {
		if (isLazyLoading && (pageNumber < totalPages)) {
			(async () => {
				await handleLoadMoreBlocks(ref)
			})()
		}
	}, [y, pageNumber, totalPages, ref])

	return (
		<div ref={ ref } className={ styles.wrapper }>
			<GiftCardBlockWrapper>
				{ renderBlock(0) }
			</GiftCardBlockWrapper>

			<div className={ styles.form }>
				<GiftCardBlockWrapper>
					{ renderBlock(1) }
				</GiftCardBlockWrapper>

				<GiftCardFormWithDetails />
			</div>

			<GiftCardBowAdornment />

			<GiftCardBlockWrapper>
				{ renderBlock(2) }
			</GiftCardBlockWrapper>
		</div>
	)
}

export { GiftCardComponent }
