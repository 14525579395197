import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isEmpty, isNull, isNil } from 'lodash'

import { Container } from '~/components/core/layout/container'
import { Breadcrumbs } from '~/components/core/breadcrumbs'
import { CmsBlocks, GIFT_CARD_TEMPLATE } from '~/components/cms'
import { GiftCard } from '~/components/giftCard'
import { CmsContext } from '~/providers/cmsProvider'
import { IRootState } from '~/state/types'
import { usePreferredStore } from '~/hooks/preferredStore'
import { UserContext } from '~/providers/userProvider'
import { ICmsProps } from '~/components/cms/types'

const Cms = (props: ICmsProps): ReactElement | null => {
	const {
		isNonContextualization,
		fullPageData = null,
	} = props
	const { content } = useContext(CmsContext)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { clearPreferredStore } = usePreferredStore()
	const { isLogged } = useContext(UserContext)

	const isLazyLoading = isNull(fullPageData)
	const title = !isLazyLoading ? fullPageData.title : isEmpty(content) ? '' : content[0].title

	const renderContent = useCallback((): ReactElement => {
		const cmsTemplate = isLazyLoading ? content[0]?.template : fullPageData.template

		if (cmsTemplate === GIFT_CARD_TEMPLATE) {
			return <GiftCard fullPageData={ fullPageData } isLazyLoading={ isLazyLoading } />
		}

		return <CmsBlocks fullPageData={ fullPageData } isLazyLoading={ isLazyLoading } />
	}, [content, fullPageData, isLazyLoading])

	useEffect(() => {
		if (!isNil(preferredStore) && isNonContextualization) {
			(async () => {
				await clearPreferredStore(isLogged)
			})()
		}
	}, [isLogged])

	return (
		<Container>
			<Breadcrumbs name={ title } />

			{ renderContent() }
		</Container>
	)
}

export { Cms }
