import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isNil } from 'lodash'

import { IGetCmsPagesParams, ICmsPageResponse, ICmsPagesFullResponse } from '~/api/dataTypes/cms'
import { request } from '~/api/requests/axios'

export const getCmsPages = async (params: IGetCmsPagesParams): Promise<AxiosResponse<ICmsPageResponse>> => {
	const {
		path,
		page = 1,
		isInternal = false,
		withStoreCode = true,
		storeCode = null,
		noCache = '',
	} = params

	const requestConfig: AxiosRequestConfig = {
		params: { path, page, noCache },
		isInternal,
		service: 'CMS_INTEGRATION_SERVICE',
		withStoreCode,
	}

	if (!isNil(storeCode)) {
		requestConfig.headers = {
			storeCode,
		}
	}

	return (
		await request.get('/v1/cms-pages', requestConfig)
	)
}

export const getCmsPagesFull = async (params: IGetCmsPagesParams): Promise<AxiosResponse<ICmsPagesFullResponse>> => {
	const {
		path,
		isInternal = false,
		withStoreCode = true,
		storeCode = null,
		noCache = '',
	} = params

	const requestConfig: AxiosRequestConfig = {
		params: { path, noCache },
		isInternal,
		withStoreCode,
		service: 'CMS_INTEGRATION_SERVICE',
	}

	if (!isNil(storeCode)) {
		requestConfig.headers = {
			storeCode,
		}
	}

	return (
		await request.get('/v1/cms-pages/full', requestConfig)
	)
}
