import React, { createContext, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useList, useToggle } from 'react-use'
import { useRouter } from 'next/router'
import { find, isEmpty, noop } from 'lodash'

import { IAdvicesContextProps, IAdvicesProviderProps, IFetchAdviceDataParams, INITIAL_ADVICES_DATA, PAGEABLE_DEFAULT_VALUE } from '~/providers/advicesProvider'
import { IAdviceData, IAdviceSortOption, IAdviceByNodeSortType, IAdviceByNodeResponse } from '~/api/dataTypes/advice'
import { IPageable } from '~/api/dataTypes/pageable'
import { getAdvicesByNode } from '~/api/requests/advices'

const initialData: IAdvicesContextProps = {
	data: INITIAL_ADVICES_DATA,
	fetchAdvicesData: noop,
	handleSetIsLoading: noop,
	pageable: PAGEABLE_DEFAULT_VALUE,
	articles: [],
	sortOptions: [],
	templateUrl: '',
	wwwUrl: '',
	hashtags: [],
	isLoading: false,
}

export const AdvicesContext = createContext(initialData)

export const AdvicesProvider = (props: IAdvicesProviderProps): ReactElement => {
	const { children, data } = props
	const { articleList, availableSortList, articleCatalogNode, templateUrl, wwwUrl, selectedFilters, promotedHashtags } = data
	const [articles, { set: setArticles }] = useList<IAdviceData>(articleList.content)
	const [pageable, setPageable] = useState<IPageable>(articleList.pageable)
	const [sortOptions, { set: setSortOptions }] = useList<IAdviceSortOption>(availableSortList)
	const [currentFilters, { set: setCurrentFilters }] = useList<string>(selectedFilters)
	const [activeSort, setActiveSort] = useState<IAdviceByNodeSortType | undefined>('default')
	const [isLoading, setIsLoading] = useToggle(false)
	const router = useRouter()

	const { id } = articleCatalogNode

	const handleSetIsLoading = useCallback((state: boolean): void => {
		setIsLoading(state)
	}, [])

	const activeSortOption = useCallback((options: IAdviceSortOption[]): IAdviceByNodeSortType | undefined => {
		const activeSortOption: IAdviceSortOption | undefined = find(options, (option: IAdviceSortOption) => option.active)

		return activeSortOption?.sortOption
	}, [sortOptions])

	const setData = useCallback(async (data: IAdviceByNodeResponse, filters: string[] = []): Promise<void> => {
		const { articleList, availableSortList } = data

		setArticles(articleList.content)
		setPageable(articleList.pageable)
		setSortOptions(availableSortList)
		setActiveSort(activeSortOption(availableSortList))

		if (!isEmpty(filters)) {
			setCurrentFilters(filters)
		}
	}, [pageable, articles, sortOptions, currentFilters])

	const fetchAdvicesData = useCallback(async (params: IFetchAdviceDataParams): Promise<void> => {
		const { perPage, pageNumber, sort, articleTypeIdList } = params

		const { data } = await getAdvicesByNode(
			{
				id,
				size: perPage || pageable.pageSize,
				page: pageNumber || pageable.pageNumber,
				sort: sort || activeSort,
				articleTypeIdList: articleTypeIdList || selectedFilters,
			},
		)

		const url = isEmpty(data.wwwUrl) ? wwwUrl : data.wwwUrl

		await setData(data, articleTypeIdList)
		await router.push(url)
	}, [pageable, currentFilters, id, activeSort, wwwUrl])

	const providerValue: IAdvicesContextProps = useMemo(() => ({
		data,
		fetchAdvicesData,
		pageable,
		articles,
		sortOptions,
		templateUrl,
		wwwUrl,
		hashtags: promotedHashtags,
		isLoading,
		handleSetIsLoading,
		currentFilters,
	}), [data, fetchAdvicesData, pageable, articles, sortOptions, templateUrl, wwwUrl, isLoading, promotedHashtags, handleSetIsLoading])

	useEffect(() => {
		setArticles(articleList.content)
		setPageable(articleList.pageable)
		setSortOptions(availableSortList)
		setActiveSort(activeSortOption([]))
	}, [id])

	return (
		<AdvicesContext.Provider value={ providerValue }>
			{ children }
		</AdvicesContext.Provider>
	)
}
