import React, { ReactElement } from 'react'

import { IAdvicesBlockWrapperProps } from '~/components/core/advicesBlock'

import styles from './AdvicesBlockWrapper.module.scss'

const AdvicesBlockWrapper = (props: IAdvicesBlockWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { AdvicesBlockWrapper }
