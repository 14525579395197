import { IAdvicesContextData } from '~/providers/advicesProvider'
import { IPageable } from '~/api/dataTypes/pageable'

export const INITIAL_ADVICES_DATA: IAdvicesContextData = {
	articleCatalogTree: {
		id: 0,
		children: [],
		count: 0,
		leaf: false,
		name: '',
		selected: false,
		wwwUrl: '',
	},
	breadCrumb: {
		breadCrumbItemList: [],
	},
	articleCatalogNode: {
		id: 0,
		name: '',
		wwwUrl: '',
		description: '',
		slotList: [],
	},
	articleTypeFilterList: [],
	articleList: {
		content: [],
		pageable: {
			totalElements: 0,
			totalPages: 0,
			hasNext: false,
			firstPage: false,
			lastPage: false,
			hasPrevious: false,
			pageSize: 0,
			pageNumber: 0,
		},
	},
	wwwUrl: '',
	templateUrl: '',
	availableSortList: [],
	selectedFilters: [],
	promotedHashtags: [],
}

export const PAGEABLE_DEFAULT_VALUE: IPageable = {
	totalElements: 0,
	totalPages: 0,
	hasNext: false,
	firstPage: false,
	lastPage: false,
	hasPrevious: false,
	pageSize: 12,
	pageNumber: 1,
}
