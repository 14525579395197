import { useContext, useCallback, RefObject } from 'react'
import { isNull } from 'lodash'

import { getCmsPages } from '~/api/requests/cms'
import { LOADING_SPACE } from '~/components/cms'
import { CmsContext } from '~/providers/cmsProvider'
import { IUseCmsBlocksData } from '~/hooks/cmsBlocks'
import { useLogError } from '~/hooks/logError'

export const useCmsBlocks = (): IUseCmsBlocksData => {
	const { pageable, content, isPending, path, handleSetData, handleSetPending } = useContext(CmsContext)
	const { sendLogError } = useLogError()

	const { pageNumber, totalPages } = pageable

	const handleLoadMoreBlocks = useCallback(async (containerRef: RefObject<HTMLDivElement>): Promise<void> => {
		if (!isNull(containerRef.current)) {
			const domRect = containerRef.current.getBoundingClientRect()
			const spaceBelow = window.innerHeight - domRect.bottom

			if (spaceBelow > LOADING_SPACE && pageNumber < totalPages && !isPending) {
				await handleGetCmsBlock()
			}
		}
	}, [pageNumber, totalPages, isPending])

	const handleGetCmsBlock = useCallback(async (): Promise<void> => {
		try {
			handleSetPending(true)

			const { data: { cmsPage } } = await getCmsPages({ path, page: pageNumber + 1 })

			handleSetData(cmsPage)
		} catch (e: unknown) {
			const nextPageNumber = pageNumber + 2

			if (nextPageNumber <= totalPages) {
				const { data: { cmsPage } } = await getCmsPages({ path, page: nextPageNumber })

				handleSetData(cmsPage)

				return
			}

			handleSetData({ content: [], pageable: { ...pageable, pageNumber: nextPageNumber } })
			sendLogError(e)
		} finally {
			handleSetPending(false)
		}
	}, [pageNumber])

	return {
		content,
		pageNumber,
		totalPages,
		isPending,
		handleLoadMoreBlocks,
	}
}
