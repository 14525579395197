import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { GiftCardDetailsHeader, GiftCardDetailsItem, IGiftCardDetailsProps } from '~/components/giftCard'
import { IGiftCardTransactionData } from '~/api/dataTypes/giftCard'

import styles from './GiftCardDetails.module.scss'

const GiftCardDetails = (props: IGiftCardDetailsProps): ReactElement | null => {
	const { data } = props
	const { t } = useTranslation(['giftCard'])

	const { accountBalance, transactionList } = data

	const renderContent = useCallback(() => (
		map(transactionList, (transaction: IGiftCardTransactionData) => (
			<GiftCardDetailsItem item={ transaction } />
		))
	), [transactionList])

	if (isEmpty(transactionList)) {
		return null
	}

	return (
		<section className={ styles.wrapper }>
			<h2 className={ styles.accountBalance }>
				{ t('currentBalance', { accountBalance }) }
			</h2>

			<GiftCardDetailsHeader />

			{ renderContent() }
		</section>
	)
}

export { GiftCardDetails }
