import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { IAdvicesBlockCarouselProps } from '~/components/core/advicesBlock'
import { CategoryCarousel } from '~/components/core/categoryCarousel'

import styles from './AdvicesBlockCarousel.module.scss'

const AdvicesBlockCarousel = (props: IAdvicesBlockCarouselProps): ReactElement => {
	const { categories } = props
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			<CategoryCarousel
				categories={ categories }
				additionalClass={ styles.categories }
				pageType="ADVICES_CATEGORY"
				caption={ t('moreAdvices') }
			/>
		</div>
	)
}

export { AdvicesBlockCarousel }
