import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isNil, isUndefined } from 'lodash'

import { IHomePageDataResponse } from '~/api/dataTypes/home'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getHomePage = async (storeCode?: string, noCache?: string | string[], isInternal?: boolean): Promise<AxiosResponse<IHomePageDataResponse>> => {
	const requestConfig: AxiosRequestConfig = {
		isInternal: !isUndefined(isInternal) ? isInternal : true,
		service: 'CMS_INTEGRATION_SERVICE',
		withStoreCode: true,
		params: getNoCacheParam(noCache),
	}

	if (!isNil(storeCode)) {
		requestConfig.headers = {
			storeCode,
		}
	}

	return await request.get('/v1/home', requestConfig)
}
