import React, { ReactElement } from 'react'

import { IAdvicesBlockArticleProps, AdvicesBlockImage, AdvicesBlockContent } from '~/components/core/advicesBlock'

import styles from './AdvicesBlockArticle.module.scss'

const AdvicesBlockArticle = (props: IAdvicesBlockArticleProps): ReactElement => {
	const { article } = props

	const { title, shortDescription, wwwUrl, imageUrl, articleType, hashtags } = article
	const isVideo = articleType === 'VIDEO'

	return (
		<div className={ styles.wrapper }>
			<AdvicesBlockImage
				isVideo={ isVideo }
				link={ wwwUrl }
				image={ imageUrl }
				title={ title }
			/>

			<AdvicesBlockContent
				title={ title }
				text={ shortDescription }
				link={ wwwUrl }
				hashtags={ hashtags }
			/>
		</div>
	)
}

export { AdvicesBlockArticle }
