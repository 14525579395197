import React, { ReactElement } from 'react'
import { SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper'
import { map } from 'lodash'

import { Carousel } from '~/components/core/carousel'
import { AdvicesBlockArticle, IAdvicesBlockArticlesProps, ADVICES_CAROUSEL_PARAMS } from '~/components/core/advicesBlock'
import { IArticleData } from '~/api/dataTypes/catalog'
import { useMediaQuery } from '~/hooks/mediaQuery'

const AdvicesBlockArticles = (props: IAdvicesBlockArticlesProps): ReactElement => {
	const { articleList } = props
	const { isTablet } = useMediaQuery()

	const carouselParams: SwiperOptions = {
		...ADVICES_CAROUSEL_PARAMS,
		spaceBetween: isTablet ? 32 : 16,
	}

	const renderContent = (): ReactElement => (
		<>
			{ map(articleList, (article: IArticleData) => {
				const { id } = article

				return (
					<SwiperSlide key={ id }>
						<AdvicesBlockArticle article={ article } />
					</SwiperSlide>
				)
			}) }
		</>
	)

	return (
		<Carousel params={ carouselParams }>
			{ renderContent() }
		</Carousel>
	)
}

export { AdvicesBlockArticles }
