import { isUndefined } from 'lodash'

import { getAppParametersData } from '~/api/requests/app'
import { vars } from '~/statics/variables'

export const getKobiFlag = async (): Promise<boolean> => {
	const { data: appParams } = await getAppParametersData()

	const { isKobiIntegrationEnabled: isKobiIntegrationEnabledByEnv } = vars
	const isKobiIntegrationEnabled = isUndefined(isKobiIntegrationEnabledByEnv) ? appParams?.kobiConfig?.integrationEnabled : isKobiIntegrationEnabledByEnv === 'true'

	return isKobiIntegrationEnabled
}
