import React, { ReactElement, useMemo, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useToggle } from 'react-use'

import { IPostGiftCardBalanceData } from '~/api/dataTypes/giftCard'
import { postGiftCardBalance } from '~/api/requests/giftCard'
import { useLogError } from '~/hooks/logError'
import { IGiftCardFormProps, initialGiftCardTransactionData } from '~/components/giftCard'
import { Button } from '~/components/core/button'
import { Input } from '~/components/core/form'
import { SectionHeader } from '~/components/core/sectionHeader'

import styles from './GiftCardForm.module.scss'

const GiftCardForm = (props: IGiftCardFormProps): ReactElement => {
	const { onTransactionData } = props
	const { t } = useTranslation(['giftCard', 'form'])
	const { sendLogError } = useLogError()
	const [hasError, setHasError] = useToggle(false)
	const [isLoading, setIsLoading] = useToggle(false)

	const schema: yup.SchemaOf<IPostGiftCardBalanceData> = useMemo(() => yup.object().shape({
		serialNumber: yup.string().required(t('required', { ns: 'form' })),
		scratchCardNumber: yup.string().required(t('required', { ns: 'form' })),
	}), [])

	const { control, handleSubmit } = useForm<IPostGiftCardBalanceData>({
		resolver: yupResolver(schema),
		defaultValues: {
			serialNumber: '',
			scratchCardNumber: '',
		},
	})

	const handleFormSubmit = useCallback(handleSubmit(async (formData: IPostGiftCardBalanceData): Promise<void> => {
		setIsLoading(true)
		setHasError(false)

		try {
			const { data } = await postGiftCardBalance(formData)

			onTransactionData(data)
		} catch (e: unknown) {
			setHasError(true)
			onTransactionData(initialGiftCardTransactionData)
			sendLogError(e)
		} finally {
			setIsLoading(false)
		}
	}), [])

	return (
		<form className={ styles.wrapper } onSubmit={ handleFormSubmit }>
			<SectionHeader
				title={ t('checkBalance', { ns: 'giftCard' }) }
			/>

			<div className={ styles.container }>
				<Input
					isRequired
					theme={ {
						wrapper: styles.inputWrapper,
						inputWrapper: styles.input,
						inputParentClass: styles.inputParentClass,
					} }
					control={ control }
					name="serialNumber"
					label={ t('form.cardNumber', { ns: 'giftCard' }) }
				/>

				<Input
					isRequired
					theme={ {
						wrapper: styles.inputWrapper,
						inputWrapper: styles.input,
						inputParentClass: styles.inputParentClass,
					} }
					control={ control }
					name="scratchCardNumber"
					label={ t('form.scratchNumber', { ns: 'giftCard' }) }
				/>
			</div>

			<Button
				isLoading={ isLoading }
				type="submit"
				text={ t('form.button', { ns: 'giftCard' }) }
				additionalClass={ styles.button }
			/>

			{ hasError && (
				<span className={ styles.error }>
					{ t('form.cardNotFound') }
				</span>
			) }
		</form>
	)
}

export { GiftCardForm }
