import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IGiftCardBlockWrapperProps } from '~/components/giftCard'

import styles from './GiftCardBlockWrapper.module.scss'

const GiftCardBlockWrapper = (props: IGiftCardBlockWrapperProps): ReactElement => {
	const {
		children,
		additionalClass = '',
	} = props

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			{ children }
		</div>
	)
}

export { GiftCardBlockWrapper }
