import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { IGiftCardCmsBlockProps } from '~/components/giftCard/giftCardCmsBlock'
import { CmsBlock } from '~/components/core/cmsBlock'

const GiftCardCmsBlock = (props: IGiftCardCmsBlockProps): ReactElement | null => {
	const { cmsBlock } = props

	if (isNull(cmsBlock)) {
		return null
	}

	const { content, name, type } = cmsBlock

	return (
		<CmsBlock
			content={ content }
			name={ name }
			type={ type }
		/>
	)
}

export { GiftCardCmsBlock }
