import { AxiosResponse } from 'axios'

import { NewsItemDataType } from '~/api/dataTypes/news'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getNews = async (id: string, noCache?: string | string[]): Promise<AxiosResponse<NewsItemDataType>> => (
	await request.get(`/v1/news/${ id }`, {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)
