import { SwiperOptions } from 'swiper'

import { vars } from '~/statics'

const { mobile, smallDesktop } = vars.breakpoints

export const ADVICES_CAROUSEL_PARAMS: SwiperOptions = {
	slidesPerView: 'auto',
	slidesPerGroup: 2,
	breakpoints: {
		[mobile]: {
			slidesPerView: 2,
			slidesPerGroup: 2,
		},
		[smallDesktop]: {
			slidesPerView: 3,
			slidesPerGroup: 3,
		},
	},
}
