import React, { ReactElement } from 'react'

import { IGiftCardDetailsItemProps } from '~/components/giftCard'

import styles from './GiftCardDetailsItem.module.scss'

const GiftCardDetailsItem = (props: IGiftCardDetailsItemProps): ReactElement => {
	const { item } = props

	const { dateTime, type, message, amount, accountBalance } = item

	return (
		<section className={ styles.wrapper }>
			<div className={ styles.dateTime }>
				{ dateTime }
			</div>

			<div className={ styles.type }>
				{ type }
			</div>

			<div className={ styles.status }>
				{ message }
			</div>

			<div className={ styles.amount }>
				{ amount }
			</div>

			<div className={ styles.accountBalance }>
				{ accountBalance }
			</div>
		</section>
	)
}

export { GiftCardDetailsItem }
