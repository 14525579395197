import { ReactElement, createContext, useMemo } from 'react'

import { IAdvicesTagsContextData, IAdvicesTagsProviderProps, INITIAL_PRODUCT_LIST_PROVIDER } from '~/providers/advicesTagsProvider'

export const AdvicesTagsContext = createContext(INITIAL_PRODUCT_LIST_PROVIDER)

export const AdvicesTagsProvider = (props: IAdvicesTagsProviderProps): ReactElement => {
	const { children, data } = props

	const providerValue: IAdvicesTagsContextData = useMemo(() => {
		const { breadCrumb, hashtagsGroups } = data

		return ({
			breadCrumb,
			hashtagsGroups,
		})
	}, [data])

	return (
		<AdvicesTagsContext.Provider value={ providerValue }>
			{ children }
		</AdvicesTagsContext.Provider>
	)
}
