import { AxiosResponse } from 'axios'

import { IPostGiftCardBalanceData, IGiftCardBalanceResponse } from '~/api/dataTypes/giftCard'
import { request } from '~/api/requests/axios'

export const postGiftCardBalance = async (data: IPostGiftCardBalanceData): Promise<AxiosResponse<IGiftCardBalanceResponse>> => (
	await request.post('/v1/gift-card-balance', data, {
		service: 'PROXY_API_SERVICE',
	})
)
