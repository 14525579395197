import { ReactElement, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useList, useToggle } from 'react-use'
import { find, isEmpty, noop } from 'lodash'

import { getAdvicesByHashtag } from '~/api/requests/advices'
import { IAdviceByNodeSortType, IAdviceData, IAdviceSortOption, IHashtagAdvicesListResponse } from '~/api/dataTypes/advice'
import { IPageable } from '~/api/dataTypes/pageable'
import { IFetchAdviceDataParams } from '~/providers/advicesProvider'

import { ITagAdvicesContextData, ITagAdvicesProviderProps } from './types'

const initialContextData: ITagAdvicesContextData = {
	breadCrumb: {
		breadCrumbItemList: [],
	},
	articles: {
		pageable: {
			totalElements: 0,
			totalPages: 0,
			hasNext: false,
			firstPage: false,
			lastPage: false,
			hasPrevious: false,
			pageSize: 0,
			pageNumber: 0,
		},
		content: [],
	},
	selectedFilters: [],
	wwwUrl: '',
	templateUrl: '',
	sortOptions: [],
	hashtagGroups: [],
	articleTypeFilters: [],
	hashtag: {
		id: 0,
		wwwUrl: '',
		name: '',
		description: '',
	},
	handleSetIsLoading: noop,
	isLoading: false,
	fetchAdvicesData: noop,
}

export const TagAdvicesContext = createContext(initialContextData)

export const TagAdvicesProvider = (props: ITagAdvicesProviderProps): ReactElement => {
	const { children, data: { breadCrumb, hashtag, articles: articleList, wwwUrl, templateUrl, sortOptions: availableSortList, hashtagGroups, selectedFilters, articleTypeFilters } } = props
	const [articles, { set: setArticles }] = useList<IAdviceData>(articleList.content)
	const [pageable, setPageable] = useState<IPageable>(articleList.pageable)
	const [sortOptions, { set: setSortOptions }] = useList<IAdviceSortOption>(availableSortList)
	const [currentFilters, { set: setCurrentFilters }] = useList<string>(selectedFilters)
	const [activeSort, setActiveSort] = useState<IAdviceByNodeSortType | undefined>('default')
	const [isLoading, setIsLoading] = useToggle(false)
	const router = useRouter()

	const { id } = hashtag

	const handleSetIsLoading = useCallback((state: boolean): void => {
		setIsLoading(state)
	}, [])

	const activeSortOption = useCallback((options: IAdviceSortOption[]): IAdviceByNodeSortType | undefined => {
		const activeSortOption: IAdviceSortOption | undefined = find(options, (option: IAdviceSortOption) => option.active)

		return activeSortOption?.sortOption
	}, [sortOptions])

	const setData = useCallback(async (data: IHashtagAdvicesListResponse, filters: string[] = []): Promise<void> => {
		const { articles: articleList, sortOptions: availableSortList } = data

		setArticles(articleList.content)
		setPageable(articleList.pageable)
		setSortOptions(availableSortList)
		setActiveSort(activeSortOption(availableSortList))

		if (!isEmpty(filters)) {
			setCurrentFilters(filters)
		}
	}, [pageable, articles, sortOptions, currentFilters])

	const fetchAdvicesData = useCallback(async (params: IFetchAdviceDataParams): Promise<void> => {
		const { perPage, pageNumber, sort, articleTypeIdList } = params

		const { data } = await getAdvicesByHashtag(
			{
				hashtagId: id,
				size: perPage || pageable.pageSize,
				page: pageNumber || pageable.pageNumber,
				sort: sort || activeSort,
				articleTypeIdList: articleTypeIdList || selectedFilters,
			},
		)

		const url = isEmpty(data.wwwUrl) ? wwwUrl : data.wwwUrl

		await setData(data, articleTypeIdList)
		await router.push(url)
	}, [pageable, currentFilters, activeSort, wwwUrl, id])

	const providerValue: ITagAdvicesContextData = useMemo(() => ({
		breadCrumb,
		articles: articleList,
		wwwUrl,
		templateUrl,
		sortOptions,
		hashtagGroups,
		selectedFilters,
		articleTypeFilters,
		handleSetIsLoading,
		isLoading,
		fetchAdvicesData,
		hashtag,
	}), [hashtag, breadCrumb, articleList, wwwUrl, templateUrl, sortOptions, hashtagGroups, selectedFilters, articleTypeFilters, handleSetIsLoading, isLoading, fetchAdvicesData])

	useEffect(() => {
		setArticles(articleList.content)
		setPageable(articleList.pageable)
		setSortOptions(availableSortList)
		setActiveSort(activeSortOption([]))
	}, [id])

	return (
		<TagAdvicesContext.Provider value={ providerValue }>
			{ children }
		</TagAdvicesContext.Provider>
	)
}
