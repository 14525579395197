import { AxiosResponse } from 'axios'

import { INewspaperResponse, INewspaperBlockResponse, INewspaperMenuResponse, IGetNewspaperBlockParams } from '~/api/dataTypes/newspaper'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getNewspaper = async (id: number, noCache?: string | string[]): Promise<AxiosResponse<INewspaperResponse>> => (
	await request.get(`/v1/newspaper/${ id }`, {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)

export const getNewspaperBlock = async (params: IGetNewspaperBlockParams): Promise<AxiosResponse<INewspaperBlockResponse>> => {
	const {
		id, page,
		isInternal = true,
		noCache = '',
	} = params

	return await request.get(`/v1/newspaper/${ id }/blocks`, {
		isInternal,
		params: { page, noCache },
		service: 'CMS_INTEGRATION_SERVICE',
	})
}

export const getNewspaperMenu = async (noCache?: string | string[]): Promise<AxiosResponse<INewspaperMenuResponse>> => (
	await request.get('/v1/newspaper/menu', {
		isInternal: true,
		service: 'CMS_INTEGRATION_SERVICE',
		params: getNoCacheParam(noCache),
	})
)
