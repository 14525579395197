import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { useWindowScroll } from 'react-use'
import { map, isNull, isEmpty } from 'lodash'

import { ICmsPageBlockData, ICmsPageContent, ISlotData } from '~/api/dataTypes/cms'
import { CmsBlock } from '~/components/core/cmsBlock'
import { Loader } from '~/components/core/loader'
import { useCmsBlocks } from '~/hooks/cmsBlocks'
import { ICmsBlocksProps } from '~/components/cms'

import styles from './CmsBlocks.module.scss'

const CmsBlocks = (props: ICmsBlocksProps): ReactElement => {
	const { fullPageData, isLazyLoading } = props
	const containerRef = useRef<HTMLDivElement>(null)
	const { y } = useWindowScroll()
	const { content, handleLoadMoreBlocks, pageNumber, totalPages, isPending } = useCmsBlocks()

	const renderFullContent = useCallback((): ReactElement => (
		<>
			{ map(fullPageData?.slots, (slot: ISlotData) => {
				if (isEmpty(slot.blockList)) {
					return null
				}

				return map(slot.blockList, (block: ICmsPageBlockData) => {
					const { content, type, name } = block

					return (
						<CmsBlock
							key={ name }
							content={ content }
							type={ type }
							name={ name }
						/>
					)
				})
			}) }
		</>
	), [fullPageData])

	const renderBlocks = useCallback((): ReactElement => (
		<>
			{ map(content, (item: ICmsPageContent) => {
				if (isNull(item.block)) {
					return null
				}

				const { content, type, name } = item.block

				return (
					<CmsBlock
						key={ name }
						content={ content }
						type={ type }
						name={ name }
					/>
				)
			}) }
		</>
	), [content])

	useEffect(() => {
		if (isLazyLoading && (pageNumber < totalPages)) {
			(async () => {
				await handleLoadMoreBlocks(containerRef)
			})()
		}
	}, [y, pageNumber, totalPages, containerRef])

	return (
		<div ref={ containerRef } className={ styles.wrapper }>
			{ isLazyLoading ? renderBlocks() : renderFullContent() }

			{ isPending && <Loader theme={ { loader: styles.loader } } /> }
		</div>
	)
}

export { CmsBlocks }
