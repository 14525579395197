import React, { ReactElement } from 'react'

import { Container } from '~/components/core/layout/container'
import { GiftCardComponent, IGiftCardProps } from '~/components/giftCard'

const GiftCard = (props: IGiftCardProps): ReactElement => {
	const { fullPageData, isLazyLoading } = props

	return (
		<Container>
			<GiftCardComponent fullPageData={ fullPageData } isLazyLoading={ isLazyLoading } />
		</Container>
	)
}

export { GiftCard }
