import { find, isUndefined, map } from 'lodash'

import { INewspaperResponse, INewspaperInfoData, INewspaperBlockResponse, INewspaperSectionMenuData } from '~/api/dataTypes/newspaper'
import { INewspaperBanner, INewspaperSection, INewspaperBlock } from '~/providers/newspaperProvider'

export const getNewspaperTopBanner = (newspaper: INewspaperResponse): INewspaperBanner => {
	const { title, topBanner } = newspaper
	const { imgUrl, pdfUrl, hotpointList, alternativeImgUrl, alternativeHotpointList } = topBanner

	return ({
		imgUrl,
		pdfUrl,
		hotpointList,
		alternativeImgUrl,
		alternativeHotpointList,
		alt: title,
	})
}

export const getNewspaperId = (newspaper: INewspaperResponse): number | null => {
	const { activeNewspaperList } = newspaper

	const activeNewspaper = find(activeNewspaperList, (newspaper: INewspaperInfoData) => newspaper.selected)

	if (isUndefined(activeNewspaper)) {
		return null
	}

	return activeNewspaper.id
}

export const getNewspaperBlockWithPosition = (block: INewspaperBlockResponse, position: number): INewspaperBlock => ({
	block,
	position,
})

export const getNewspaperSections = (newspaper: INewspaperResponse, blocks: INewspaperBlock[]): INewspaperSection[] => {
	const { menuSectionList } = newspaper

	return map(menuSectionList, (menuSection: INewspaperSectionMenuData) => {
		const { position: menuPosition } = menuSection
		const sectionBlock = find(blocks, (block: INewspaperBlock) => block.position === menuPosition)

		const result: INewspaperSection = {
			...menuSection,
			block: !isUndefined(sectionBlock) ? sectionBlock.block : null,
		}

		return result
	})
}

export const isSectionExist = (newspaper: INewspaperResponse, sectionId: number): boolean => {
	const { menuSectionList } = newspaper

	return !isUndefined(find(menuSectionList, (section: INewspaperSectionMenuData) => section.position === sectionId))
}

export const isSectionFetched = (blocks: INewspaperBlock[], sectionId: number): boolean => (
	!isUndefined(find(blocks, (block: INewspaperBlock) => block.position === sectionId))
)
