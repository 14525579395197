import React, { createContext, ReactElement, useMemo, useCallback, useState, useEffect } from 'react'
import { useToggle } from 'react-use'
import { isUndefined } from 'lodash'

import { ICmsPage } from '~/api/dataTypes/cms'
import { ICmsContextProps, ICmsProviderProps, INITIAL_CMS_PROVIDER } from '~/providers/cmsProvider'

export const CmsContext = createContext(INITIAL_CMS_PROVIDER)

const CmsProvider = (props: ICmsProviderProps): ReactElement => {
	const { children, value: { data, path } } = props
	const [cmsBlocksData, setCmsBlocksData] = useState<ICmsPage>(data || INITIAL_CMS_PROVIDER)
	const [isPending, setIsPending] = useToggle(false)

	const handleSetPending = useCallback((state: boolean): void => {
		setIsPending(state)
	}, [])

	const handleSetData = useCallback(({ pageable, content }: ICmsPage) => {
		setCmsBlocksData((prevData: ICmsPage) => ({
			pageable,
			content: [...prevData.content, ...content],
		}))
	}, [])

	useEffect(() => {
		if (!isUndefined(data)) {
			setCmsBlocksData(data)
		}
	}, [data])

	const providerValue = useMemo((): ICmsContextProps => {
		const { content, pageable } = cmsBlocksData

		return ({
			content,
			pageable,
			path,
			isPending,
			handleSetPending,
			handleSetData,
		})
	}, [data, path, cmsBlocksData, isPending, handleSetData, handleSetPending])

	return (
		<CmsContext.Provider value={ providerValue }>
			{ children }
		</CmsContext.Provider>
	)
}

export { CmsProvider }
