import React, { ReactElement, useCallback, useState } from 'react'

import { GiftCardDetails, GiftCardForm, initialGiftCardTransactionData } from '~/components/giftCard'
import { IGiftCardBalanceResponse } from '~/api/dataTypes/giftCard'

import styles from './GiftCardFormWithDetails.module.scss'

const GiftCardFormWithDetails = (): ReactElement => {
	const [transactionData, setTransactionData] = useState<IGiftCardBalanceResponse>(initialGiftCardTransactionData)

	const handleTransactionData = useCallback((data: IGiftCardBalanceResponse) => {
		setTransactionData(data)
	}, [])

	return (
		<div className={ styles.wrapper }>
			<GiftCardForm onTransactionData={ handleTransactionData } />

			<GiftCardDetails data={ transactionData } />
		</div>
	)
}

export { GiftCardFormWithDetails }
