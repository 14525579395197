import React, { ReactElement, createContext, useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { isNull, noop } from 'lodash'

import { INewspaperContextProps, INewspaperProviderProps, INewspaperBlock, isSectionFetched, isSectionExist, INewspaperBanner, getNewspaperTopBanner, INewspaperSection, getNewspaperBlockWithPosition, getNewspaperId, getNewspaperSections } from '~/providers/newspaperProvider'
import { INewspaperBlockResponse } from '~/api/dataTypes/newspaper'
import { getNewspaperBlock } from '~/api/requests/newspaper'

const initialProps: INewspaperContextProps = {
	activeSection: 0,
	title: '',
	menuItems: [],
	sections: [],
	topBanner: {} as INewspaperBanner,
	setSectionByMenu: noop,
	setSectionByScroll: noop,
}

export const NewspaperContext = createContext(initialProps)

export const NewspaperProvider = (props: INewspaperProviderProps): ReactElement => {
	const { children, data } = props
	const [activeSection, setActiveSection] = useState<number>(initialProps.activeSection)
	const [blocks, setBlocks] = useState<INewspaperBlock[]>([getNewspaperBlockWithPosition(data.firstSection, 0)])
	const semaphoreTimeout = useRef<NodeJS.Timeout | null>(null)

	useEffect(() => {
		handleFetchSectionData(1)
	}, [])

	const handleSetSemaphoreOff = useCallback((): void => {
		semaphoreTimeout.current = null
	}, [semaphoreTimeout])

	const handleSetSemaphoreOn = useCallback((): void => {
		if (!isNull(semaphoreTimeout.current)) {
			clearTimeout(semaphoreTimeout.current)
		}

		semaphoreTimeout.current = setTimeout(handleSetSemaphoreOff, 1000)
	}, [semaphoreTimeout, handleSetSemaphoreOff])

	const handleAddSectionBlock = useCallback((position: number, block: INewspaperBlockResponse): void => {
		setBlocks((oldState: INewspaperBlock[]) => [...oldState, getNewspaperBlockWithPosition(block, position)])
	}, [setBlocks, getNewspaperBlockWithPosition])

	const handleFetchSectionData = useCallback(async (sectionId: number): Promise<void> => {
		const id = getNewspaperId(newspaper)

		if (id && isSectionExist(newspaper, sectionId) && !isSectionFetched(blocks, sectionId)) {
			const response = await getNewspaperBlock({ id, page: sectionId + 1, isInternal: false })
			const { data } = response

			handleAddSectionBlock(sectionId, data)
		}
	}, [getNewspaperId, isSectionExist, isSectionFetched, getNewspaperBlock, handleAddSectionBlock])

	const handleChangeSection = useCallback((sectionId: number): void => {
		if (activeSection !== sectionId) {
			handleFetchSectionData(sectionId)
			handleFetchSectionData(sectionId + 1)
			setActiveSection(sectionId)
		}
	}, [activeSection, setActiveSection, handleFetchSectionData])

	const setSectionByMenu = useCallback((sectionId: number): void => {
		handleSetSemaphoreOn()
		handleChangeSection(sectionId)
	}, [handleSetSemaphoreOn, handleChangeSection])

	const setSectionByScroll = useCallback((sectionId: number): void => {
		if (isNull(semaphoreTimeout.current)) {
			handleChangeSection(sectionId)
		}
	}, [handleSetSemaphoreOn, handleChangeSection])

	const { newspaper } = data
	const { menuSectionList, title } = newspaper
	const topBanner = getNewspaperTopBanner(newspaper)
	const sections: INewspaperSection[] = useMemo(() => getNewspaperSections(newspaper, blocks), [newspaper, blocks])

	const providerValue: INewspaperContextProps = useMemo(() => ({
		activeSection,
		menuItems: menuSectionList,
		topBanner,
		title,
		sections,
		setSectionByMenu,
		setSectionByScroll,
	}), [menuSectionList, topBanner, title, activeSection, sections, setSectionByMenu, setSectionByScroll])

	return (
		<NewspaperContext.Provider value={ providerValue }>
			{ children }
		</NewspaperContext.Provider>
	)
}
