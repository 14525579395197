import React, { ReactElement, useEffect, useState } from 'react'
import classNames from 'classnames'

import { Image } from '~/components/core/image'
import { vars } from '~/statics'
import { useMozaic } from '~/hooks/mozaic'

import styles from './GiftCardBowAdornment.module.scss'

const GiftCardBowAdornment = (): ReactElement => {
	const { getShouldUseMozaicFlag } = useMozaic()
	const [imgSrc, setImgSrc] = useState<string>('')

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const bowImage = shouldUseMozaic ? vars.images.mozaicBow : vars.images.bow

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	useEffect(() => {
		setImgSrc(shouldUseMozaic ? `${ window.location.origin }${ bowImage }` : bowImage)
	}, [shouldUseMozaic, bowImage])

	return (
		<div className={ wrapperClass }>
			<Image
				className={ styles.image }
				src={ imgSrc }
				width={ 298 }
				height={ 129 }
				alt=""
				role="presentation"
			/>
		</div>
	)
}

export { GiftCardBowAdornment }
