import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { IAdvicesBlockProp, AdvicesBlockArticles, AdvicesBlockWrapper, AdvicesBlockHeader, AdvicesBlockCarousel } from '~/components/core/advicesBlock'
import { WatermarkPlaceholder } from '~/components/core/watermarkPlaceholder'

const AdvicesBlock = (props: IAdvicesBlockProp): ReactElement | null => {
	const { categories, articleBanner } = props

	if (isNull(articleBanner)) {
		return null
	}

	const { articleBannerHeader, articleBannerDescription, articleList } = articleBanner

	return (
		<AdvicesBlockWrapper>
			<AdvicesBlockHeader title={ articleBannerHeader } description={ articleBannerDescription } />

			<AdvicesBlockArticles articleList={ articleList } />

			<WatermarkPlaceholder size="sm" text="POST MVP">
				<AdvicesBlockCarousel categories={ categories } />
			</WatermarkPlaceholder>
		</AdvicesBlockWrapper>
	)
}

export { AdvicesBlock }
