import { AxiosResponse } from 'axios'

import { IFaqResponse } from '~/api/dataTypes/faq'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getFaqData = async (noCache?: string | string[]): Promise<AxiosResponse<IFaqResponse>> => (
	await request.get('/v1/faq', {
		service: 'CMS_INTEGRATION_SERVICE',
		isInternal: true,
		params: getNoCacheParam(noCache),
	})
)
